import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import React from "react";

let utils = {}

utils.evalCell = (value) => {
    let finalValue = ""
    if(value instanceof Array){
        finalValue = JSON.stringify(value)
    }
    else if(value === "boolean"){
        finalValue = value ? "true" : "false";
    }
    else {
        finalValue = value
    }
    return finalValue || <WarningRoundedIcon color="primary"/>
}

export default utils