import React, {useContext} from 'react'
import {EntityReserveContext} from "../../../context/EntityReserveContext";
import GenericTable from "../../tables/GenericTable";
import TitleHeader from "../../tables/TitleHeader";
import Table from "@material-ui/core/Table";
import EmptyTable from "../../tables/EmptyTable";
import {TableBody} from "@material-ui/core";
import EntityReserveRowItem from "./EntityReserveRowItem";
import EntityReserveRowHeader from "./EntityReserveRowHeader";

const EntityReservesTable = () => {
    const {entityReserves} = useContext(EntityReserveContext)

    return <GenericTable header={<TitleHeader title={"Entity Reserves"}/>}>
        <Table aria-label={"Entity Reserves"}>
            { entityReserves.length === 0 && <EmptyTable/> }
            { entityReserves.length > 0 &&
                <React.Fragment>
                    <EntityReserveRowHeader/>
                    <TableBody>
                        {
                            entityReserves.map((reserve, index) =>
                                <EntityReserveRowItem item={reserve} key={"key-auditor-reserve-" + reserve.id + "-row"}/>
                            )
                        }
                    </TableBody>
                </React.Fragment>
            }
        </Table>    </GenericTable>

}

export default EntityReservesTable;