import axios from 'axios'
import {TOKEN_STORAGE} from "../localstorage.constants";

let Axios = axios.create({})


if(process.env.NODE_ENV === 'production'){
    Axios.defaults.baseURL = window.location.origin + "/api"
}
else{
    var api_port = process.env.REACT_APP_API_PORT || 8080;
    Axios.defaults.baseURL = "http://localhost:" + api_port + "/api"
}

Axios.defaults.headers.common = {
    'Content-Type':'application/json',
    'Authorization': "Bearer " +localStorage.getItem(TOKEN_STORAGE),
    'responseType': 'json'
}

Axios.configure = (token) => {
    Axios.defaults.headers.common['Authorization'] = "Bearer " + token
    return token
}

Axios.getCancelToken = () => {
    return axios.CancelToken.source()
}
export default Axios