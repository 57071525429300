import React, { useContext } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { PopUpContext, PopUpDispatch, CLOSE } from "../../context/PopUpContext";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
}));


export default function PopUpComponent() {
    const classes = useStyles();

    const { popUpDispatch } = useContext(PopUpDispatch)
    const { isOpen, child } = useContext(PopUpContext)



    return (
        <Grid>
            <Backdrop className={classes.backdrop} open={isOpen} onClick={() => popUpDispatch({type:CLOSE})}>
                {child}
            </Backdrop>
        </Grid>
    )
      
}
