import React from 'react'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

class NoDataToShow extends React.Component {
    render(){
        return (
            <Grid>
                <Typography component="h5"> No data to show </Typography>
            </Grid>
        )
    }
}

export default NoDataToShow