import React, {useContext, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {InitForm, NotificationDispatch} from "@ekratos/ekratos-lib-components";
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router-dom'
import AuthApi from "../../api/AuthApi";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({

    gridContainer: {
        background: "radial-gradient(circle, rgba(255,255,255,1) 78%, rgba(170,170,170,1) 100%)",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        maxWidth:"100%",
        height:"100vh",
        paddingBottom: "200px"
    },
    title: {
        minWidth: "350px",
        textAlign: "center"
    },
    loginContainer: {
        minWidth: "350px"
    },

}))

const InitComponent = ({match}) => {
    let username = match.params.token
    const [loading, setLoading] = useState(false)
    const classes = useStyles()
    const history = useHistory()
    const {t} = useTranslation()
    const {notificationDispatch} = useContext(NotificationDispatch)


    useEffect(() => {
        setLoading(true)
        AuthApi.getInit(username)
            .then((res) => {setLoading(false)})
            .catch(err => history.push('/login'))
    },[history, username])

    const submit = (password, repeatPassword) => {
        if(password !== repeatPassword){
            notificationDispatch({type: "set", message: t('user_init_error_password_not_equals'), variant: "error"})
        }
        else{
            AuthApi.init(username, password)
                .then(res => history.push('/login'))
                .catch(err => notificationDispatch({type: "set", message: t("user_init_error_internal") + " -> " + err.message, variant:'error'}))
        }
    }

    return <Grid container direction="column" justify="center" alignItems="center" className={classes.gridContainer}>
        <Typography variant={"h5"} className={classes.title} >{t('password_init_title')}</Typography>
        <Grid item className={classes.loginContainer}>
            <InitForm
                onSubmit={submit}
                passwordLabel={t("user_init_label_password")}
                repeatPasswordLabel={t("user_init_label_repeat_password")}
                submitText={t("user_init_button_send")}
                loggingIn={loading}/>
        </Grid>
    </Grid>
}

export default InitComponent;