import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


export const TITLE='Are you sure?'
export const YES='Yes'
export const NO='No'

const useStyles = makeStyles( (theme) => ({
    root: {
        minWidth: 150,
        padding:theme.spacing(2)
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    buttons:{
        display:'flex',
        justifyContent:'space-between'
    }
}));

export default function SimplePopUp({text,confirmFunction}) {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography gutterBottom variant="h6" component="h2">
                    {TITLE}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {text}
                </Typography>
            </CardContent>
            <CardActions className={classes.buttons}>
                <Button id='simple_pop_up_confirm_button' variant="contained" color="primary" onClick={() => confirmFunction()}>
                    {YES}
                </Button>
                <Button variant="contained" color="secondary">
                    {NO}
                </Button>
            </CardActions>
        </Card>
    );
}
