import React, {useContext} from 'react'
import {EntitiesContext} from "../../../context/EntitiesContext";
import VersionProvider from "../../../context/VersionContext";
import GenericTable from "../../tables/GenericTable";
import EntityRowItem from "./EntityRowItem";
import EntityRowHeader from "./EntityRowHeader";
import {Table} from "@material-ui/core";
import EmptyTable from "../../tables/EmptyTable";
import TitleVersionHeader from "../../tables/TitleVersionHeader";
import TableBody from "@material-ui/core/TableBody";

const EntitiesTable = () => {

    const {entities} = useContext(EntitiesContext)
    return <VersionProvider>
       <GenericTable header={<TitleVersionHeader title={"Database Entities"} serviceName={{back:"auditor",front:"auditor-react"}}/>}>
            <Table aria-label={"Database Entities" || "Generic Table"}>
                { entities.length === 0 &&
                <EmptyTable/>
                }
                { entities.length > 0 &&
                    <React.Fragment>
                        <EntityRowHeader/>
                        <TableBody>
                            { entities.map(entity =>
                                <EntityRowItem item={entity} key={"key-" + entity.identity + "-row"}/>
                            )}
                        </TableBody>
                    </React.Fragment>
                }
            </Table>
        </GenericTable>
    </VersionProvider>
}

export default EntitiesTable;