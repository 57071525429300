import React, {useEffect, useState} from 'react'
import VersionApi from "../api/VersionApi";

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
const VersionContext = React.createContext();

export default function VersionProvider({children}) {

    const [auditorFrontVersions, setAuditorFrontVersions] = useState([])
    const [organizerFrontVersions, setOrganizerFrontVersions] = useState([])
    const [auditorVersions, setAuditorVersions] = useState([])
    const [organizerVersions, setOrganizerVersions] = useState([])
    const [selectedAuditorFrontVersion, setSelectedAuditorFrontVersion] = useState()
    const [selectedOrganizerFrontVersion, setSelectedOrganizerFrontVersion] = useState()
    const [selectedAuditorVersion, setSelectedAuditorVersion] = useState()
    const [selectedOrganizerVersion, setSelectedOrganizerVersion] = useState()
    const [error, setError] = useState("")

    const changeDefaultVersion = (version, serviceName) => {

        VersionApi.update(version, serviceName)
            .then(() =>{
                if(serviceName === "auditor"){
                    setSelectedAuditorVersion(version)
                }
                else if(serviceName === "organizer"){
                    setSelectedOrganizerVersion(version)
                }else if(serviceName === "auditor-react"){
                    setSelectedAuditorFrontVersion(version)
                } 
                else{
                    setSelectedOrganizerFrontVersion(version)
                }
            })
            .catch(err => setError(err.message))
    }

    useEffect(() => {
        VersionApi.getDefault()
            .then(res => {
                setSelectedAuditorVersion(res.data.find(el => el.serviceName === "auditor").version)
                setSelectedOrganizerVersion(res.data.find(el => el.serviceName === "organizer").version)
                setSelectedAuditorFrontVersion(res.data.find(el => el.serviceName === "auditor-react").version)
                setSelectedOrganizerFrontVersion(res.data.find(el => el.serviceName === "organizer-react").version)
            })
            .then(() => VersionApi.getNexus("auditor"))
            .then(res => setAuditorVersions(res.data.map(element => element.version)))
            .then(() => VersionApi.getNexus("organizer"))
            .then(res => setOrganizerVersions(res.data.map(element => element.version)))
            .then(() => VersionApi.getNexus("auditor-react"))
            .then(res => setAuditorFrontVersions(res.data.map(element => element.version)))
            .then(() => VersionApi.getNexus("organizer-react"))
            .then(res => setOrganizerFrontVersions(res.data.map(element => element.version)))
            .catch(err => setError(err.message))
    }, [])

    return(
        <VersionContext.Provider
            value={{
                auditorFrontVersions,
                auditorVersions,
                organizerVersions,
                organizerFrontVersions,
                error,
                selectedAuditorFrontVersion,
                selectedAuditorVersion,
                selectedOrganizerVersion,
                selectedOrganizerFrontVersion,
                changeDefaultVersion: changeDefaultVersion
            }}
        >
            {children}
        </VersionContext.Provider>
    )
}

export { VersionContext }