import Axios from '../axios/axios'
import {TOKEN_STORAGE} from "../localstorage.constants";

const AuthApi = {
    login: (email, password) =>
        Axios.post("/auth/login", {username: email, password:password})
            .then(res => res.headers.authorization.replace("Bearer ", ""))
            .then(token => Axios.configure(token))
            .then(token => localStorage.setItem(TOKEN_STORAGE, token)),
    self: () => Axios.get("/users/self"),
    getInit: (username) => Axios.get("/users/init/" + username),
    init: (username, password) => Axios.put("/users/init",{username: username, password: password})
}

export default AuthApi