import Axios from "../axios/axios";

const EntityAPI = {
    get: () => Axios.get("/entity"),
    getReserves: () => Axios.get("/entity/reserves"),
    restartBack: (entity) => Axios.put("/entity/restart/back", {identity: entity.identity, id: entity.id}),
    restartFront: (entity) => Axios.put("/entity/restart/front", {identity: entity.identity, id: entity.id}),
    restart: (entity) => Axios.put("/entity/restart", {identity: entity.identity, id: entity.id}),
    delete: (entity) => Axios.delete("/entity/" + entity.id),
    updateVersion: (entity) => Axios.put("/entity/version", entity),
    deleteReserve: (reserveId) => Axios.delete('/entity/reserve/' + reserveId),
    create: (entity) => Axios.post("/entity", entity),
    getReserve: (reserveId) => Axios.get("/entity/reserve/" + reserveId)
}

export default EntityAPI