import React from 'react'
import Grid from '@material-ui/core/Grid'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {ChevronLeft, MenuRounded, Person} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withTheme } from '@material-ui/core/styles';
import {Header} from "mui-layout";

class PrivateHeader extends React.Component {

    render(){
        return (
            <Header
                renderMenuIcon={open => (open ? <ChevronLeft /> : <MenuRounded />)}
                style={{
                    fontWeight: "3em",
                    color: this.props.theme.palette.secondary.main,
                    backgroundColor: "#ECEBEA",
                }}>
                <Grid container
                      justify="center"
                      alignItems="center"
                >
                    <Grid item xs={6} container justify="flex-start">
                        <Typography variant="h4" style={{ fontWeight: 'bold', color: "black" }}>
                            { this.props.name }
                        </Typography>
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                        <Grid item>
                            <IconButton
                                key="entity"
                                aria-label="entity"
                                color="inherit"
                                onClick={() => this.props.history.push("/entity")}>
                                <Person></Person>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton
                                key="help"
                                aria-label="help"
                                color="inherit"
                                onClick={() => alert("We are still working on this feature")}>
                                <HelpOutlineIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Header>
        )
    }
}

export default withTheme(PrivateHeader)