import React, {useContext, useEffect, useState} from 'react'
import GenericTableRow from "../../tables/GenericTableRow";
import Utils from "../../Utils";
import TableCell from "@material-ui/core/TableCell";
import {makeStyles} from "@material-ui/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import EntityReservesListActions from "../../../actions/EntityReservesListActions";
import {EntityReserveDispatcher} from "../../../context/EntityReserveContext";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CancelIcon from '@material-ui/icons/Cancel';
import EntityAPI from "../../../api/EntityAPI";
import {EntitiesDispatcher} from "../../../context/EntitiesContext";

const useStyles = makeStyles(theme => ({
    progress: {
        color: theme.palette.primary.main
    },
    failedColor: {
        color: "red"
    }
}))

const EntityReserveRowItem = ({item}) => {

    // TODO : Estic acabant de fer l'item, ha de sortir la rodeta!
    const classes = useStyles()
    const {entityReserveDispatcher} = useContext(EntityReserveDispatcher)
    const {entitiesDispatcher} = useContext(EntitiesDispatcher)

    const [failedReserve, setFailedReserve] = useState(false)


    useEffect(() => {

        // with 5 it makes 6 waits and 5 attemps (if timout is 5000, will wait for 30 seconds) .
        let attempts = 5;

        let attempt = (attempts) => setTimeout(() => {
            if(attempts > 0) {
                EntityAPI.getReserve(item.id)
                    .then(() => attempts -= 1)
                    .then(() => attempt(attempts))
                    .catch(err => {
                        entityReserveDispatcher({type: 'delete', reserveId: item.id})
                        EntityAPI.get()
                            .then(res => entitiesDispatcher({type: 'set', entities: res.data}))
                            .catch(err => entitiesDispatcher({type: 'error', message: err.message}))
                    })
            }
            else {
                setFailedReserve(true)
            }
        }, 5000)

        attempt(attempts)
    }, [item, entityReserveDispatcher, entitiesDispatcher, setFailedReserve])

    return <GenericTableRow >
        <React.Fragment>
            <TableCell align={"center"}>{Utils.evalCell(item.id)}</TableCell>
            <TableCell align={"center"}>{Utils.evalCell(item.identity)}</TableCell>
            <TableCell align={"center"}>{Utils.evalCell(item.address)}</TableCell>
            <TableCell align={"center"}>{Utils.evalCell(item.reserve_date)}</TableCell>
            <TableCell align={"center"}>{Utils.evalCell(item.type)}</TableCell>
            <TableCell align={"center"}>{Utils.evalCell(item.version)}</TableCell>
            <TableCell align={"center"}>{Utils.evalCell(item.organizerVersion)}</TableCell>
            <TableCell align={"center"}>
                {
                    !failedReserve && <LinearProgress className={classes.progress}/>
                }
                {
                    failedReserve && <CancelIcon className={classes.failedColor}/>
                }
            </TableCell>
            <TableCell align={"center"}>
                {
                    EntityReservesListActions(entityReserveDispatcher).map((action, index) =>
                        <Tooltip title={action.title} key={"button-action-reserve-" + index}>
                            <IconButton
                                aria-label={action.title}
                                color="inherit"
                                className={action.withColor ?  item.dockerState === "running" ? classes.buttonOn : classes.buttonOff : classes.buttonNeutral}
                                onClick={() => action.action(item)}>
                                <action.icon/>
                            </IconButton>
                        </Tooltip>)
                }
            </TableCell>
        </React.Fragment>
    </GenericTableRow>


}

export default EntityReserveRowItem;