import React, { useEffect, useReducer} from 'react'
import EntityAPI from "../api/EntityAPI";

const SET_ACTION = "set"
const RESTART_ACTION = "restart"
const START_ACTION = "start"
const DELETE_ACTION = "delete"
const UPDATE_ACTION = "update"

const EntitiesContext = React.createContext()
const EntitiesDispatcher = React.createContext()

function entitiesReducer(state, action) {
    switch (action.type) {
        case SET_ACTION: {
            return {
                entities: action.entities,
            }
        }
        case UPDATE_ACTION: {
            return {
                entities: action.entities,
            }
        }
        case RESTART_ACTION:
            let entities = state.entities.filter(e => e.identity !== action.entity.identity)
            return {
                ...state,
                entities: [action.entity, ...entities]
            }
        case START_ACTION:
            return {
                entities: action.entities
            }
        case DELETE_ACTION:
            return {
                entities: state.entities.filter(e => e.identity !== action.entity.identity)
            }
        default:
            break;
    }
}

let initialState = {
    entities: [],
}

const EntitiesContextComponent = ({children}) => {

    const [state, dispatch] = useReducer(entitiesReducer, initialState)

    useEffect(() => {
        EntityAPI.get()
            // TODO : AQuí tenim un pollo... s'ha de poder passar el canvi de versió d'alguna altre forma.
            .then(res => res.data)
            .then(entities => dispatch({type: SET_ACTION, entities: entities}))
            .catch(err => console.log(err))
    }, [])

    return <EntitiesDispatcher.Provider value={{entitiesDispatcher: dispatch}}>
        <EntitiesContext.Provider value={{entities: state.entities}}>
        {children}
        </EntitiesContext.Provider>
    </EntitiesDispatcher.Provider>
}

export {EntitiesContext}
export {EntitiesDispatcher}

export default EntitiesContextComponent;