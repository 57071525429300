import React from 'react'
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import TitleHeader from "./TitleHeader";
import TableContainer from "@material-ui/core/TableContainer";

export default function GenericTable({children, header}) {
    let titleHeader = header || <TitleHeader/>
    return (
        <Paper>
            <TableContainer>
                {titleHeader}
                <Divider/>
                    {children}
            </TableContainer>
        </Paper>
    )
}
