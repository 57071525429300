import React, { useContext, useState } from 'react'
import Grid from "@material-ui/core/Grid";
import GenericTableRow from "../../tables/GenericTableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import { Typography } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/styles";
import Utils from '../../Utils'
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import VersionSelector from "../VersionSelector.component";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { EntitiesDispatcher } from "../../../context/EntitiesContext";
import { updateEntityVersion } from "../../../services/EntitiesService";
import CircularProgress from "@material-ui/core/CircularProgress";
import { EntityReserveDispatcher } from "../../../context/EntityReserveContext";
import EntitiesListActions from '../../../actions/EntitiesListActions';
import { NotificationDispatch } from '@ekratos/ekratos-lib-components';
import SplitButton from '../../buttons/splitButton';
import StorageIcon from '@material-ui/icons/Storage';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import { ButtonGroup } from '@material-ui/core';
import PauseIcon from '@material-ui/icons/Pause';
import { Avatar, ListItemSecondaryAction, ListItemAvatar } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PanToolIcon from '@material-ui/icons/PanTool';
import { PopUpDispatch, SET_POPUP } from '../../../context/PopUpContext';
import SimplePopUp from '../../../components/PopUp/SimplePopUp';

const styles = makeStyles(theme => ({
    buttonOn: {
        color: "green",
        cursor: 'default'
    },
    buttonOff: {
        color: "red",
        cursor: 'default'
    },
    buttonNeutral: {
        color: "black"
    },
    progress: {
        color: theme.palette.primary.main,
    },
    warning: {
        color: "#FFA016",
        cursor: 'default'
    },
    success: {
        color: "#76C27A",
        cursor: 'default'
    },
    error: {
        color: "#FF0000",
        cursor: 'default'
    }
}))

export default function EntityRowItem({ item }) {


    const classes = styles()
    const [open, setOpen] = useState(false)
    const { entitiesDispatcher } = useContext(EntitiesDispatcher)
    const { entityReserveDispatcher } = useContext(EntityReserveDispatcher)
    const { notificationDispatch } = useContext(NotificationDispatch)
    const { popUpDispatch } = useContext(PopUpDispatch)


    const menuOptions = () => {
        const menuList = {
            restart: [],
            other: [],
        }

        EntitiesListActions(item).forEach((action, index) => {
            const optionAction = () => popUpDispatch({ type: SET_POPUP, child: <SimplePopUp text={action.title} confirmFunction={() => action.action(notificationDispatch, entitiesDispatcher, item, entityReserveDispatcher)} /> })
            const optionObj = { action: optionAction, name: action.title, icon: action.icon }

            if (action.type !== 'restart') {
                menuList.other.push(<Tooltip title={action.title} key={"button-action-" + index}>
                    <IconButton
                        aria-label={action.title}
                        color="inherit"
                        onClick={() => optionAction()}>
                        {action.icon}
                    </IconButton>
                </Tooltip>)
            } else {
                menuList.restart.push(optionObj)
            }
        })

        menuList.restart.sort((a, b) => {
            return a.priority === b.priority ? 0 : a.priority < b.priority ? 1 : -1
        })

        const defaultOption = menuList.restart.shift()


        return <Grid container alignItems='center' display='row'>
            <Grid item>
                <SplitButton defaultOption={defaultOption} options={menuList.restart}></SplitButton>
            </Grid>
            <Grid item>
                {menuList.other}
            </Grid>
        </Grid>
    }


    const getIconStatus = (status) => {
        let icon = <Tooltip title='Stop'><IconButton className={classes.error}><PanToolIcon /></IconButton></Tooltip>
        if (status === 'run') {
            icon = <Tooltip title='Run'><IconButton className={classes.success}><CheckCircleOutlineIcon /></IconButton></Tooltip>
        }
        else if (status === 'wait') {
            icon = <Tooltip title='Wait'><IconButton className={classes.warning}><PauseIcon /></IconButton></Tooltip>
        }
        return icon;
    }


    const getLastElementFromArray = (data, sep) => {
        let arr = ['']
        if(data){
            arr = data.split(sep)
        }
        return arr.pop()
    }

    return (
        <GenericTableRow open={open}>
            <React.Fragment>
                {/* <TableCell align={"center"}>{Utils.evalCell(item.id)}</TableCell> */}
                <TableCell align={"center"}>{Utils.evalCell(item.identity)}</TableCell>
                <TableCell align={"center"}>{Utils.evalCell(item.address)}</TableCell>
                <TableCell align={"center"}>{Utils.evalCell(item.adminMail)}</TableCell>
                <TableCell align={"center"}>{Utils.evalCell(getLastElementFromArray(item.dockerImage, '/'))}</TableCell>
                <TableCell align={"center"}>{Utils.evalCell(getLastElementFromArray(item.frontImage, '/'))}</TableCell>
                <TableCell align={"center"}>{Utils.evalCell(item.organizerVersion)}</TableCell>
                <TableCell>
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                        <Tooltip title='Front Status'>
                            <IconButton className={item.containerState.frontend === 'run' ? classes.buttonOn : classes.buttonOff}><DesktopWindowsIcon /></IconButton>
                        </Tooltip>
                        <Tooltip title='Back Status'>
                            <IconButton className={item.containerState.backend === 'run' ? classes.buttonOn : classes.buttonOff}><StorageIcon /></IconButton>
                        </Tooltip>
                    </ButtonGroup>
                </TableCell>
                <TableCell align={"center"}>
                    <Grid container justify={"center"} alignItems={"center"} display='row'>
                        <Grid item>
                            <Tooltip title={"Collapse"} key={"button-action-"}>
                                <IconButton
                                    aria-label={"Collapse"}
                                    color="inherit"
                                    className={classes.buttonNeutral}
                                    onClick={() => setOpen(!open)}>
                                    {open && <ArrowDropUpIcon />}
                                    {!open && <ArrowDropDownIcon />}
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            {!item.restarting &&
                                menuOptions()
                            }
                        </Grid>
                        <Grid item>
                            {
                                item.restarting && <CircularProgress size="20px" className={classes.progress}></CircularProgress>
                            }
                        </Grid>
                    </Grid>
                </TableCell>
                
            </React.Fragment>
            <React.Fragment>
                <Grid container>
                    <Grid container item>
                        <Grid item xs={6}>
                            <List diplay='row'>
                                <ListItem>
                                    <Typography variant='h6' >Auditor Info</Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"Images"} secondary={
                                        <>
                                            <Typography variant={"body2"}>Backend: {item.dockerImage}</Typography>
                                            <Typography variant={"body2"}>Frontend: {item.frontImage}</Typography>
                                        </>
                                    } />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"Ekratos Address"} secondary={item.ekratosAddress} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={3}>
                            <List>
                                <ListItem>
                                    <Typography variant='h6' >Auditor Version</Typography>
                                </ListItem>
                                <ListItem>
                                    <VersionSelector serviceName={"auditor"}
                                        changeVersion={(version, service) => updateEntityVersion(entitiesDispatcher, item, service, version)}
                                        selectedVersion={item.version} />
                                </ListItem>
                                <ListItem>
                                    <VersionSelector serviceName={"auditor-react"}
                                        changeVersion={(version, service) => updateEntityVersion(entitiesDispatcher, item, service, version)}
                                        selectedVersion={item.frontVersion} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={3}>
                            <List>
                                <ListItem>
                                    <Typography variant='h6' >Organizer Version</Typography>
                                </ListItem>
                                <ListItem>
                                    <VersionSelector
                                        serviceName={"organizer"}
                                        changeVersion={(version, service) => updateEntityVersion(entitiesDispatcher, item, service, version)}
                                        selectedVersion={item.organizerVersion} />
                                </ListItem>
                                <ListItem>
                                    <VersionSelector
                                        serviceName={"organizer-react"}
                                        changeVersion={(version, service) => updateEntityVersion(entitiesDispatcher, item, service, version)}
                                        selectedVersion={item.organizerFrontVersion} />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <List>
                            <ListItem>
                                <Typography variant='h6' >Auditor Status</Typography>
                            </ListItem>
                        </List>
                        <Grid container item xs={12}>
                            <Grid item xs={6}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                < DesktopWindowsIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Front Status" secondary={item.containerState.frontend} />
                                        <ListItemSecondaryAction>
                                            {getIconStatus(item.containerState.frontend)}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={6}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <StorageIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Back Status" secondary={item.containerState.backend} />
                                        <ListItemSecondaryAction>
                                            {getIconStatus(item.containerState.backend)}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        </GenericTableRow >
    )
}


