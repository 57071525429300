import React from 'react'

import {Button} from '@material-ui/core';
import Logo from '../imatges/404.png'
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/styles";
import {useHistory} from 'react-router-dom'

const useStyles= makeStyles(theme => ({
    root: {
       background: "radial-gradient(circle, rgba(255,255,255,1) 78%, rgba(170,170,170,1) 100%)",
       backgroundPosition: 'center',
       backgroundSize: 'cover',
       backgroundRepeat: 'no-repeat',
       maxWidth:"100%",
       height:"100vh"
    },
    buttonContainer: {
        maxWidth: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    },
    button: {
        width: "150px",
        margin: "10px"
    },
    paragraph: {
        fontSize: "1.5em"
    },
    logo: {
        maxWidth: "80%",
        maxHeight: "254px",
    }
}))

const NotFoundScreen = () => {

    const {t} = useTranslation()
    const classes = useStyles()
    const history = useHistory()

    const goHome = () => {
        history.push("/")
    }

    const goContact = () => {
        history.push("/contact")
    }


    return <Grid container direction="column" justify="center" alignItems="center" className={classes.root}>
        <img src={Logo} className={classes.logo} alt="Not found with a 404"/>
        <p className={classes.paragraph}>{t('page_not_available')}</p>
        <div className={classes.buttonContainer}>
            <Button className={classes.button} variant="contained" onClick={goHome}>
                {t('go_to_home')}
            </Button>
            <Button className={classes.button} variant="contained" onClick={goContact}>
                {t('go_to_contact')}
            </Button>
        </div>
    </Grid>
}

export default NotFoundScreen;