import React, {useContext} from 'react'
import { Redirect, Route } from 'react-router-dom'
import { Root, Content, presets } from 'mui-layout';
import SideNav from "../private/nav/SideNav";
import PrivateHeader from "../private/privateHeader";
import {useTheme} from "@material-ui/styles";
import { AuthContext } from '../context/AuthContext';
const config = presets.createCozyLayout();
config.collapsible = { sm: true, xs:false}


const PrivateRoute = ({ component: Component, ...rest }) => {

    const { authenticated } = useContext(AuthContext)

    const theme = useTheme()
    return (
        <Route
            {...rest}
            render={props =>
                authenticated ? (
                    <Root config={config}>
                        <PrivateHeader {...rest} {...props} ></PrivateHeader>
                        <SideNav {...rest} {...props} />
                        <Content style={{ marginTop: theme.spacing(3)}}>
                            <Component {...rest} {...props} authenticated  />
                        </Content>
                    </Root>
                ) : (
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
            }
        />
    )
}

export default PrivateRoute

