import React from 'react'
import { Container, Grid } from '@material-ui/core'
import AddEntity from './components/Entities/AddEntity.component'
import EntitiesTable from "./components/Entities/EntitiesTable";
import EntitiesContext from "../context/EntitiesContext";
import AuditorReserveContext from "../context/EntityReserveContext";
import EntitiesReservesTable from './components/EntityReserves/EntityReservesTable'

export default function Dashboard() {

    return (
        <Container maxWidth="xl">
            <AuditorReserveContext>
                <EntitiesContext>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AddEntity/>
                        </Grid>
                        <Grid item xs={12}>
                            <EntitiesReservesTable/>
                        </Grid>
                        <Grid item xs={12}>
                            <EntitiesTable/>
                        </Grid>
                    </Grid>
                </EntitiesContext>
            </AuditorReserveContext>
        </Container>
    )
}