import React from 'react'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/styles";


const useStyles = makeStyles(theme => ({
    padded: {
        padding: "16px"
    },
}))

export default function TitleHeader ({title}) {

    const classes = useStyles()

    return (
        <Grid container justify={"space-between"}>
            <Grid item className={classes.padded}>
                <Typography variant="h5">
                    { title || "Untitled Table" }
                </Typography>
            </Grid>
        </Grid>
    )
}