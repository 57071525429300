import React, { useState,useContext } from 'react'
import GenericTableRow from "../../tables/GenericTableRow";
import TableCell from "@material-ui/core/TableCell";
import Utils from '../../Utils'
import StorageIcon from '@material-ui/icons/Storage';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import { makeStyles } from "@material-ui/styles";
import {
    ButtonGroup, Tooltip, IconButton, Grid, List,
    ListItem, Typography, ListItemText, ListItemAvatar, Avatar, ListItemSecondaryAction
} from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PauseIcon from '@material-ui/icons/Pause';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PanToolIcon from '@material-ui/icons/PanTool';
import OrganizerAPI from '../../../api/OrganizerAPI';
import {OrganizersDispatcher,DELETE} from '../../../context/OrganizersContext';
import DeleteIcon from "@material-ui/icons/Delete";
import { PopUpDispatch, SET_POPUP } from '../../../context/PopUpContext';
import SimplePopUp from '../../../components/PopUp/SimplePopUp';
import { NotificationDispatch } from '@ekratos/ekratos-lib-components';

const styles = makeStyles(theme => ({
    buttonOn: {
        color: "green",
        cursor: 'default'
    },
    buttonOff: {
        color: "red",
        cursor: 'default'
    },
    buttonNeutral: {
        color: "black"
    },
    progress: {
        color: theme.palette.primary.main,
    },
    warning: {
        color: "#FFA016",
        cursor: 'default'
    },
    success: {
        color: "#76C27A",
        cursor: 'default'
    },
    error: {
        color: "#FF0000",
        cursor: 'default'
    }
}))


export default function OrganizerRowItem({ item }) {

    const [open, setOpen] = useState(false)
    const classes = styles()
    const {popUpDispatch} = useContext(PopUpDispatch)
    const {organizersDispatcher} = useContext(OrganizersDispatcher)
    const {notificationDispatch} = useContext(NotificationDispatch)

    const deleteOrganizer = () => {
        if(!(item.dockerState && item.dockerState === 'running')){
            OrganizerAPI.delete(item.id)
            .then(() => organizersDispatcher({type:DELETE,organizer:item}))
            .catch(err => notificationDispatch({type:"set", variant:"error", message: err.message}))
        }
        else{
            notificationDispatch({type:"set", variant:"error", message: "Cannot delete a running poll"})
        }    
    }

    const getIconStatus = (status) => {
        let icon = <Tooltip title='Stop'><IconButton className={classes.error}><PanToolIcon /></IconButton></Tooltip>
        if (status === 'run') {
            icon = <Tooltip title='Run'><IconButton className={classes.success}><CheckCircleOutlineIcon /></IconButton></Tooltip>
        }
        else if (status === 'wait') {
            icon = <Tooltip title='Wait'><IconButton className={classes.warning}><PauseIcon /></IconButton></Tooltip>
        }
        return icon;
    }

    return (
        <GenericTableRow open={open}>
            <React.Fragment>
                <TableCell align={"center"}>{Utils.evalCell(item.identity)}</TableCell>
                <TableCell align={"center"}>{Utils.evalCell(item.address)}</TableCell>
                <TableCell align={"center"}>{Utils.evalCell(item.ekratosAddress)}</TableCell>
                <TableCell align={"center"}>{Utils.evalCell(item.version)}</TableCell>
                <TableCell align={"center"}>{Utils.evalCell(item.frontVersion)}</TableCell>
                <TableCell align={"center"}>{Utils.evalCell(item.ownerIdentity)}</TableCell>
                <TableCell>
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                        <Tooltip title='Front Status'>
                            <IconButton className={item.containerState && item.containerState.frontend === 'run' ? classes.buttonOn : classes.buttonOff}><DesktopWindowsIcon /></IconButton>
                        </Tooltip>
                        <Tooltip title='Back Status'>
                            <IconButton className={item.containerState && item.containerState.backend === 'run' ? classes.buttonOn : classes.buttonOff}><StorageIcon /></IconButton>
                        </Tooltip>
                    </ButtonGroup>
                </TableCell>
                <TableCell>
                    <Tooltip title={"Delete"} key={"button-action-" + item.identity}>
                        <IconButton
                            aria-label={"delete"}
                            color="inherit"
                            onClick={() => popUpDispatch({ type: SET_POPUP, child: <SimplePopUp text={"delete"} confirmFunction={deleteOrganizer} />})}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell align={"center"}>
                    <Grid container justify={"center"} alignItems={"center"} display='row'>
                        <Tooltip title={"Collapse"} key={"button-action-"}>
                            <IconButton
                                aria-label={"Collapse"}
                                color="inherit"
                                className={classes.buttonNeutral}
                                onClick={() => setOpen(!open)}>
                                {open && <ArrowDropUpIcon />}
                                {!open && <ArrowDropDownIcon />}
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </TableCell>
            </React.Fragment>
            <React.Fragment>
                <Grid container>
                    <Grid container item>
                        <Grid item xs={12} md={4}>
                            <List diplay='row'>
                                <ListItem>
                                    <Typography variant='h6' >Organizer Info</Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"Images"} secondary={
                                        <>
                                            <Typography variant={"body2"}>Backend: {item.dockerImage}</Typography>
                                            <Typography variant={"body2"}>Frontend: {item.frontImage}</Typography>
                                        </>
                                    } />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"Ekratos Address"} secondary={item.ekratosAddress} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <List>
                                <ListItem>
                                    <ListItemText primary={"Versions"} secondary={
                                        <>
                                            <Typography variant={"body2"}>Backend: {item.version}</Typography>
                                            <Typography variant={"body2"}>Frontend: {item.frontVersion}</Typography>
                                        </>
                                    } />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <List>
                                <ListItem>
                                    <Typography variant={"body1"}>Status</Typography>
                                </ListItem>
                                {item.containerState &&
                                    <>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <DesktopWindowsIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Front Status" secondary={item.containerState.frontend} />
                                            <ListItemSecondaryAction>
                                                {getIconStatus(item.containerState.frontend)}
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <StorageIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Back Status" secondary={item.containerState.backend} />
                                            <ListItemSecondaryAction>
                                                {getIconStatus(item.containerState.backend)}
                                            </ListItemSecondaryAction>
                                        </ListItem>

                                    </>}
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        </GenericTableRow>
    )
}