import React, {useState, useContext, useEffect, useCallback} from 'react';
import Grid from "@material-ui/core/Grid";
import {
    NotificationDispatch,
    LoginForm
} from '@ekratos/ekratos-lib-components'

import {AuthContext} from "../../context/AuthContext";
import {useHistory} from 'react-router-dom'
import AuthApi from "../../api/AuthApi";
import {makeStyles} from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({

    gridContainer: {
        background: "radial-gradient(circle, rgba(255,255,255,1) 78%, rgba(170,170,170,1) 100%)",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        maxWidth:"100%",
        height:"100vh",
        paddingBottom: "200px"
    },
    title: {
        minWidth: "350px",
        textAlign: "center"
    },
    loginContainer: {
        minWidth: "350px"
    },

}))

export default function Login() {

    const {notificationDispatch} = useContext(NotificationDispatch)
    const {authenticated, setAuthenticated} = useContext(AuthContext)
    const history = useHistory()
    const {t} = useTranslation()

    const [loading, setLoading] = useState(false)
    const classes = useStyles()


    const submit = useCallback((username, password) => {
        setLoading(true)
        AuthApi.login(username, password)
            .then(() => setAuthenticated(true))
            .then(() => setLoading(false))
            .then(() => history.push("/"))
            .catch(err => {
                notificationDispatch({type: 'set', message:t('login_error_authentication'), variant: "error" })
                setLoading(false)
            })
    }, [setAuthenticated, notificationDispatch, history, t])

    useEffect(() => {
        if(authenticated) {
            history.push("/")
        }
    }, [authenticated, history, submit])

    return (

        <Grid container direction="column" justify="center" alignItems="center" className={classes.gridContainer}>
                <Typography variant={"h5"} className={classes.title} >{t('login_title')}</Typography>

                <Grid item className={classes.loginContainer}>
                    <LoginForm
                        onSubmit={submit}
                        emailLabel={t("login_label_address")}
                        passwordLabel={t("login_label_password")}
                        submitText={t("login_button_send")}
                        loggingIn={loading}
                        recover={{
                            text: t(''),
                            action: () => history.push("/recover")
                        }}/>
                </Grid>
        </Grid>

    )

}