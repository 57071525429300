import React, {useContext} from 'react'
import VersionProvider from "../../../context/VersionContext";
import TitleVersionHeader from "../../tables/TitleVersionHeader";
import {Table} from "@material-ui/core";
import EmptyTable from "../../tables/EmptyTable";
import TableBody from "@material-ui/core/TableBody";
import GenericTable from "../../tables/GenericTable";
import {OrganizersContext} from "../../../context/OrganizersContext";
import OrganizerRowHeader from "./OrganizerRowHeader";
import OrganizerRowItem from "./OrganizerRowItem";

const OrganizersTable = () => {

    const {organizers} = useContext(OrganizersContext)

    return  <VersionProvider>
        <GenericTable header={<TitleVersionHeader title={"Database Organizers"} serviceName={{back:"organizer",front:"organizer-react"}}/>}>
            <Table aria-label={"Database Organizers"}>
                { organizers.length === 0 &&
                <EmptyTable/>
                }
                { organizers.length > 0 &&
                <React.Fragment>
                    <OrganizerRowHeader/>
                    <TableBody>
                        { organizers.map(organizer =>
                            <OrganizerRowItem item={organizer} key={"key-" + organizer.identity + "-row"}/>
                        )}
                    </TableBody>
                </React.Fragment>
                }
            </Table>
        </GenericTable>
    </VersionProvider>
}

export default OrganizersTable;