import React, { useEffect, useReducer } from 'react'
import OrganizerAPI from "../api/OrganizerAPI";

const GET = 'GET'
export const DELETE = 'DELETE'

const initState = {
    organizers: [],
    error: ""
}

const OrganizersContext = React.createContext()
const OrganizersDispatcher = React.createContext()

function organizersReducer(state, action) {
    switch (action.type) {
        case GET:
            return {
                ...state,
                organizers: action.organizers
            }
        case DELETE:
            return {
                organizers: state.organizers.filter(e => e.identity !== action.organizer.identity)
            }
        default:
            break;
    }
}

const OrganizerContextComponent = ({ children }) => {

    const [state, dispatch] = useReducer(organizersReducer, initState)

    useEffect(() => {
        OrganizerAPI.list()
            .then(res => dispatch({ type: GET, organizers: res.data }))
            .catch(err => dispatch({ type: "error", message: err.message }))
    }, [])

    return <OrganizersDispatcher.Provider value={{ organizersDispatcher: dispatch }}>
        <OrganizersContext.Provider value={{ organizers: state.organizers }}>
            {children}
        </OrganizersContext.Provider>
    </OrganizersDispatcher.Provider>
}

export { OrganizersContext }
export { OrganizersDispatcher }
export default OrganizerContextComponent;