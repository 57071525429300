import React, {useContext} from 'react'
import ChevronRight from "@material-ui/core/SvgIcon/SvgIcon";
import { Nav } from 'mui-layout';
import {ChevronLeft} from "@material-ui/icons";
import People from '@material-ui/icons/People';
import PollIcon from '@material-ui/icons/Poll';
import Apartment from '@material-ui/icons/Apartment';
import Grid from "@material-ui/core/Grid";
import Logo from '../../imatges/ekratos.jpg'
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {AuthContext} from "../../context/AuthContext";
import {useHistory} from 'react-router-dom'
import {makeStyles} from "@material-ui/styles";
import {PopUpDispatch, SET_POPUP} from '../../context/PopUpContext';
import SimplePopUp from '../../components/PopUp/SimplePopUp';


const useStyles = makeStyles(theme => ({
    nav: {
        backgroundColor: "#394263"
    },
    logoGrid: {
        height: "64px"
    },
    img: {
        maxWidth: "90%",
        margin: "8px",
        maxHeight: "48px"
    }
}))

export default function SideNav() {
    const {setAuthenticated} = useContext(AuthContext)
    const history = useHistory()
    const classes = useStyles()

    const { popUpDispatch } = useContext(PopUpDispatch)

    const exit = () => {
        localStorage.clear()
        setAuthenticated(false)
        history.push("/login")
    }

    return(
        <Nav renderIcon={collapsed =>
            collapsed ? <ChevronRight /> : <ChevronLeft />
            } className={classes.nav}>
            <Grid container justify={"center"} className={classes.logoGrid} alignItems="center" onClick={() => history.push("/")}>
                <img src={Logo} alt={"Entity Logo"} className={classes.img} />
            </Grid>
            <Divider></Divider>
            <List>
                <ListItem button  onClick={() => {history.push("/")}}>
                    <ListItemIcon><Apartment></Apartment></ListItemIcon>
                    <ListItemText primary={"Entities"} />
                </ListItem>
                <ListItem button  onClick={() => {history.push("/organizers")}}>
                    <ListItemIcon><PollIcon></PollIcon></ListItemIcon>
                    <ListItemText primary={"Organizers"} />
                </ListItem>
                <ListItem button  onClick={() => {history.push("/users")}}>
                    <ListItemIcon><People></People></ListItemIcon>
                    <ListItemText primary={"Users"} />
                </ListItem>
                <Divider></Divider>
                <ListItem button  onClick={() => popUpDispatch({type:SET_POPUP,child:<SimplePopUp text={'Log out'} confirmFunction={exit}/>})}>
                    <ListItemIcon><ExitToAppIcon></ExitToAppIcon></ListItemIcon>
                    <ListItemText primary={"Logout"} />
                </ListItem>
            </List>
        </Nav>
    )
}

