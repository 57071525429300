import React, { useContext } from 'react'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import VersionSelector from "../components/VersionSelector.component";
import { VersionContext } from "../../context/VersionContext";


const useStyles = makeStyles(theme => ({
    padded: {
        padding: "16px"
    },
}))

export default function TitleHeader({ title, serviceName }) {

    const classes = useStyles()
    const { changeDefaultVersion } = useContext(VersionContext)

    return (
        <Grid container justify={"space-between"}>
            <Grid item className={classes.padded}>
                <Typography variant="h5">
                    {title || "Untitled Table"}
                </Typography>
            </Grid>
            <Grid container item justifyContent='flex-end' className={classes.padded}>
                <Grid item className={classes.padded}>
                    <VersionSelector serviceName={serviceName.back} changeVersion={changeDefaultVersion} />
                </Grid>
                {serviceName.front &&
                    <Grid item className={classes.padded}>
                        <VersionSelector serviceName={serviceName.front} changeVersion={changeDefaultVersion} />
                    </Grid>
                }

            </Grid>
        </Grid>
    )
}