import React, { useEffect, useReducer} from 'react'
import EntityAPI from "../api/EntityAPI";

let initialState = {
    entityReserves: [],
}

const EntityReserveContext = React.createContext()
const EntityReserveDispatcher = React.createContext()

function auditorReserveReducer(state, action) {
    switch (action.type) {
        case 'set':
            return {
                ...state,
                entityReserves: action.entityReserves
            }
        case 'delete':
            return {
                ...state,
                entityReserves: state.entityReserves.filter(r => r.id !== action.reserveId)
            }
        default:
            return state;
    }
}

const EntityReserveContextComponent = ({children}) => {

    const [state, dispatch] = useReducer(auditorReserveReducer, initialState)

    useEffect(() => {
        EntityAPI.getReserves()
            .then(res => res.data)
            .then(entityReserves => dispatch({type: 'set', entityReserves:entityReserves}))
            .catch(err => dispatch({type: "error", message: err.message }))
    },[])

    return <EntityReserveDispatcher.Provider value={{ entityReserveDispatcher: dispatch}}>
        <EntityReserveContext.Provider value={{entityReserves: state.entityReserves}}>
            {children}
        </EntityReserveContext.Provider>
    </EntityReserveDispatcher.Provider>
}

export {EntityReserveContext}
export {EntityReserveDispatcher}

export default EntityReserveContextComponent;