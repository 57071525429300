import React from 'react'
import TableRow from "@material-ui/core/TableRow";
import {TableCell, TableHead} from "@material-ui/core";
import NoDataToShow from "./NoDataToShow";

const EmptyTable = () => {
    return <TableHead>
        <TableRow>
            <TableCell>
                <NoDataToShow/>
            </TableCell>
        </TableRow>
    </TableHead>
}

export default EmptyTable;