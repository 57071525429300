import React from 'react'
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
    Grid
} from '@material-ui/core'
import Collapse from "@material-ui/core/Collapse";
import {makeStyles} from "@material-ui/styles";

const styles = makeStyles(theme => ({
    collapsedRoot: {
        backgroundColor: "#E9EBEC"
    },
    collapsedCell: {
        paddingBottom: 0,
        paddingTop: 0
    }
}))

export default function GenericTableRow ({children, open}) {

    const classes = styles()
    return (
        <React.Fragment>
            <TableRow>
                {children[0] ? children[0] : children}
            </TableRow>
            <TableRow className={classes.collapsedRoot}>
                <TableCell colSpan={'100%'} className={classes.collapsedCell}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Grid>
                            {children[1] && children[1]}
                        </Grid>
                    </Collapse> 
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

