import React, {useContext, useReducer, useState} from 'react'
import { Grid, Button, TextField } from '@material-ui/core'
import Title from '../Title.component'
import {EntityReserveDispatcher} from "../../../context/EntityReserveContext";
import EntityAPI from "../../../api/EntityAPI";
import {NotificationDispatch, Loader} from '@ekratos/ekratos-lib-components'
let initialState = {
    name: "",
    adminMail: "",
    error: "",
}

function createEntityReducer(state, action) {
    switch (action.type) {
        case 'field':
            return {
                ...state,
                [action.field]: action.value
            }
        case 'created':
            return {
                ...state,
                name: '',
                adminMail: ''
            }
        default:
            return state;
    }
}

const AddEntityComponent = () => {

    const {notificationDispatch} = useContext(NotificationDispatch)
    const [state, dispatchFields] = useReducer(createEntityReducer, initialState)
    const {entityReserveDispatcher} = useContext(EntityReserveDispatcher)

    const [addEntityEnabled, setAddEntityEnabled] = useState(true)

    const createEntity = () => {
        setAddEntityEnabled(false)
        let entity = {
            name: state.name,
            identity: state.name,
            cipherAlgorithm: "RSA",
            keySize: "4096",
            adminMail: state.adminMail,
            isProduction: true
        }
        EntityAPI.create(entity)
            .then(result => EntityAPI.getReserves())
            .then(res => res.data)
            .then(reserves => entityReserveDispatcher({type: 'set', entityReserves: reserves}))
            .then(() => dispatchFields({type: 'created'}))
            .then(() => notificationDispatch({type: 'set', message: "Entity has been added", variant: "success"}))
            .then(() => setAddEntityEnabled(true))
            .catch(error => {
                notificationDispatch({type: 'set', message: "Canno't create entity due to: " + error.message, variant: "error"})
                setAddEntityEnabled(true)
            })
    }

    return <Grid container>
        <Title>Add new Entity:</Title>
        <Grid item xs={12}>
            <TextField
                fullWidth
                id="new-entity-name"
                label="Entity name"
                type="text"
                value={state.name}
                onChange={(e) => dispatchFields({type: 'field', field: 'name', value: e.target.value})}
                margin="normal"
                required
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                fullWidth
                id="new-entity-email"
                label="Entity Admin Email"
                type="text"
                value={state.adminMail}
                onChange={(e) => dispatchFields({type: 'field', field: 'adminMail', value: e.target.value})}
                margin="normal"
                required
            />
        </Grid>
        <Grid item xs={12} container justify="center">
            {
                addEntityEnabled ?
                    <Button variant="contained" color="primary" onClick={ createEntity } >Add Entity</Button>
                :
                    <Loader transparent={true}/>
            }
        </Grid>
    </Grid>
}

export default AddEntityComponent;