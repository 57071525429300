import React from 'react'

import { Container } from '@material-ui/core'
import OrganizersTable from "./components/Organizers/OrganizersTable";
import OrganizersContext from "../context/OrganizersContext";

export default function Organizers() {

    return <Container maxWidth="xl">
        <OrganizersContext>
            <OrganizersTable/>
        </OrganizersContext>
    </Container>

}

