import React, { Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Redirect } from 'react-router-dom';
import notFoundScreen from "./Screens/notFoundScreen";
import Init from "./components/Authentication/Init.component";
import Login from "./components/Authentication/Login.component"
import PrivateRoute from "./routing/PrivateRoute";
import Dashboard from './private/Dashboard'
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import Users from "./private/Users";
import Organizers from "./private/Organizers";
import Grid from "@material-ui/core/Grid";
import AuthContext from './context/AuthContext'
import { CustomAlert, NotificationProvider, Loader } from '@ekratos/ekratos-lib-components'
import CssBaseline from "@material-ui/core/CssBaseline";
import PopUpProvider from './context/PopUpContext';
import PopUpComponent from './components/PopUp/PopUpComponent';

const baseTheme = createMuiTheme({
    typography: {
        fontFamily: ["Montserrat", "sans-serif"],
    },
    palette: {
        primary: { main: "#3353DC" },
        secondary: { main: "#000" },
    },
});

export default function App() {
    return (
        <ThemeProvider theme={baseTheme}>
            <Suspense fallback={<Loader absolute transparent />}>
                <PopUpProvider>
                    <PopUpComponent />
                    <Grid>
                        <NotificationProvider>
                            <CustomAlert />
                            <AuthContext>
                                <CssBaseline />
                                <Router basename="/">
                                    <Switch>
                                        <PrivateRoute path="/" exact component={Dashboard} />
                                        <Route path="/init/:token" exact component={Init} />
                                        <Route path="/login" exact component={Login} />
                                        <PrivateRoute path="/entities" exact component={Dashboard} />
                                        <PrivateRoute path="/users" exact component={Users} />
                                        <PrivateRoute path="/organizers" exact component={Organizers} />
                                        <Route path="/404" component={notFoundScreen} />
                                        <Redirect to="/404" />
                                    </Switch>
                                </Router>
                            </AuthContext>
                        </NotificationProvider>
                    </Grid>
                </PopUpProvider>
            </Suspense>
        </ThemeProvider>
    )
}