import EntityAPI from "../api/EntityAPI";

export const startEntity = (dispatch, entity) => {

    if(entity.dockerState !== "running"){
        return EntityAPI.restart(entity)
            .then(res => dispatch({type: "start", entity: entity}) )
    }
    else{
        dispatch({ type: "error", message: "Entity " + entity.identity + " already started."})
    }
}

export const updateEntityVersion = (dispatch, entity, service, version) => {
    if(service === "auditor") {
        entity.version = version

    }
    else if(service==="organizer"){
        entity.organizerVersion = version
    }
    else if(service === "auditor-react"){
        entity.frontVersion = version
    }
    else {
        entity.organizerFrontVersion = version
    }
    return EntityAPI.updateVersion(entity)
        .then(() => EntityAPI.get())
        .then(result => result.data)
        .then(entities => dispatch({type: "update", entities: entities}))
}


export const deleteEntity = (dispatch, entity) => {
    return EntityAPI.delete(entity)
        .then(result => dispatch({type: "delete", entity: entity}))
}

export const updateEntity = (dispatch, entity) => {
    entity.restarting = true
    return EntityAPI.restart(entity)
        .then(res => dispatch({type: "restart", entity: entity}))
}

export const updateEntityBack = (dispatch, entity) => {
    entity.restarting = true
    return EntityAPI.restartBack(entity)
        .then(res => dispatch({type: "restart", entity: entity}))
}

export const updateEntityFront = (dispatch, entity) => {
    entity.restarting = true
    dispatch({type: "restart", entity: entity})
    return EntityAPI.restartFront(entity)  
}