import DeleteIcon from "@material-ui/icons/Delete";
import UpdateIcon from '@material-ui/icons/Update';
import { updateEntity, deleteEntity, updateEntityFront, updateEntityBack } from '../services/EntitiesService'
import EntityAPI from "../api/EntityAPI";
import StorageIcon from '@material-ui/icons/Storage';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';

const ERROR_RESTART_MESSAGE = 'Canno\'t restart entity'
const ERROR_DELETE_MESSAGE = 'Canno\'t delete entity'



const EntitiesListActions = (entity) => [
    /* {
        title: "Start",
        action: (dispatch, entity) => startEntity(dispatch, entity),
        icon: <PlayCircleOutlineIcon/>,
        errorMessage: "Canno't start entity " + entity.identity + ".",
        withColor: true,
        type: 'start'
    }, */
    {
        title: "Restart",
        action: (notificationDispatch, dispatch, entity, entityReserveDispatcher) => updateEntity(dispatch, entity)
            .then(() => EntityAPI.getReserves())
            .then(res => entityReserveDispatcher({ type: 'set', entityReserves: res.data }))
            .catch(() => notificationDispatch({ type: "set", variant: "error", message: ERROR_RESTART_MESSAGE + " " + entity.identity }))
        ,
        icon: <UpdateIcon />,
        type: 'restart',
        priority: 1
    },
    {
        title: "Restart Back",
        action: (notificationDispatch, dispatch, entity, entityReserveDispatcher) => updateEntityBack(dispatch, entity)
            .then(() => EntityAPI.getReserves())
            .then(res => entityReserveDispatcher({ type: 'set', entityReserves: res.data }))
            .catch(() => notificationDispatch({ type: "set", variant: "error", message: ERROR_RESTART_MESSAGE + " " + entity.identity }))
        ,
        icon: <StorageIcon />,
        type: 'restart',
        priority: 2
    },
    {
        title: "Restart Front",
        action: (notificationDispatch, dispatch, entity) => updateEntityFront(dispatch, entity)
            .then(() => EntityAPI.get())
            .then(res => dispatch({ type: 'set', entities: res.data }))
            .catch(() => notificationDispatch({ type: "set", variant: "error", message: ERROR_RESTART_MESSAGE + " " + entity.identity }))
            .then(() => EntityAPI.get())
            .then((res) => dispatch({ type: "set", entities: res.data }))
        ,
        icon: <DesktopWindowsIcon />,
        type: 'restart',
        priority: 3
    },
    {
        title: "Delete",
        action: (notificationDispatch, dispatch, entity) => deleteEntity(dispatch, entity)
            .catch(() => notificationDispatch({ type: "set", variant: "error", message: ERROR_DELETE_MESSAGE + " " + entity })),
        icon: <DeleteIcon />,
        type: 'delete'
    }
]

export default EntitiesListActions;