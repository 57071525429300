import React from 'react'
import {TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const EntitiesReserveRowHeader = ({item}) => {
    return <TableHead>
        <TableRow>
            <TableCell align={"center"}>Id</TableCell>
            <TableCell align={"center"}>Identity</TableCell>
            <TableCell align={"center"}>Address</TableCell>
            <TableCell align={"center"}>Reserve Date</TableCell>
            <TableCell align={"center"}>Type</TableCell>
            <TableCell align={"center"}>Version</TableCell>
            <TableCell align={"center"}>Organizer Version</TableCell>
            <TableCell align={"center"}>Process</TableCell>
            <TableCell align={"center"}>Actions</TableCell>
        </TableRow>
    </TableHead>
}

export default EntitiesReserveRowHeader;