import React from 'react'

export const CLOSE='CLOSE'
export const SET_POPUP='SET_POPUP'


const PopUpContext = React.createContext();
const PopUpDispatch = React.createContext();


export const reducer = (prevState, action) => {
    switch (action.type) {
        case SET_POPUP:
            return {
                ...prevState,
                isOpen: true,
                child: action.child,
            }
        case CLOSE:
            return {
                ...prevState,
                isOpen: false,
                child: null
            }
        default:
            return { prevState }
    }
}

const initState = {
    isOpen: false,
    child: null
}



export default function PopUp({ children }) {

    const [state, dispatch] = React.useReducer(reducer, initState)

    return (
        <PopUpDispatch.Provider value={{ popUpDispatch: dispatch }}>
            <PopUpContext.Provider value={state}>
                {children}
            </PopUpContext.Provider>
        </PopUpDispatch.Provider>
    )
}

export { PopUpContext }
export { PopUpDispatch }