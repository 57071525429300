import React from 'react'
import axios from 'axios'
import { Grid, Container, Typography } from '@material-ui/core'

class Users extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            users: []
        }
    }

    componentDidMount() {
        axios.get(axios.defaults.baseURL + "/users").then(result => {
            this.setState({ users: result.data })
        })
        .catch(error => {

        })
    }

    render() {
        return (
            <Container>
                <Grid>
                    {this.state.users.map((user, key) => {
                        return (
                            <Grid key={key}>
                                <Typography>
                                    { user.id } -> { user.email }
                                </Typography>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        )
    }
}
export default Users