import DeleteIcon from "@material-ui/icons/Delete";
import {deleteEntityReserve} from '../services/EntityReservesService'

const EntityReservesListActions = (dispatch) => [
    {
        title: "Delete",
        action: (reserve) => deleteEntityReserve(dispatch, reserve.id),
        icon: DeleteIcon
    }
]

export default EntityReservesListActions;