import React from 'react'
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";

export default function EntityRowHeader() {


    return (
        <TableHead>
            <TableRow>
                {/* <TableCell align="center">Id</TableCell> */}
                <TableCell align="center">Identity</TableCell>
                <TableCell align="center">Address</TableCell>
                <TableCell align="center">Admin Mail</TableCell>
                <TableCell align="center">Backend Version</TableCell>
                <TableCell align="center">Frontend Version</TableCell>
                <TableCell align="center">Organizer Version</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Actions</TableCell>
            </TableRow>
        </TableHead>
    )
}