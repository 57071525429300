import React, { useEffect, useState } from 'react';
import {USER_STORAGE} from "../localstorage.constants";
import AuthApi from '../api/AuthApi'

export const AuthContext = React.createContext();

const AuthContextComponent = ({children}) => {

    const [authenticated, setAuthenticated] = useState(false)

    useEffect(() => {
        AuthApi.self()
            .then(res => localStorage.setItem(USER_STORAGE, JSON.stringify(res.data)))
            .then(() => setAuthenticated(true))
            .catch(err => setAuthenticated(false))
    }, [])

    const authContext = {
        authenticated,
        setAuthenticated
    }

    return (
        <AuthContext.Provider value={authContext}>
            {children}
        </AuthContext.Provider>
    );

}

export default AuthContextComponent;