import React, {useContext} from 'react'
import {FormControl, Grid} from '@material-ui/core'
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {makeStyles} from "@material-ui/styles";
import InputLabel from "@material-ui/core/InputLabel";
import {VersionContext} from "../../context/VersionContext";

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: "200px"
    }
}))

export default function VersionSelector({serviceName, changeVersion, selectedVersion}) {

    let versions = []
    let selected = selectedVersion

    const context =  useContext(VersionContext)

    if(serviceName === "auditor"){
        versions = context.auditorVersions
        if(!selected) selected = context.selectedAuditorVersion
    }
    else if(serviceName ==="organizer"){
        versions = context.organizerVersions
        if(!selected) selected = context.selectedOrganizerVersion
    }
    else if(serviceName === "auditor-react"){
        versions = context.auditorFrontVersions
        if(!selected) selected = context.selectedAuditorFrontVersion
    }
    else{
        versions = context.organizerFrontVersions
        if(!selected) selected = context.selectedOrganizerFrontVersion
    }

    const classes = useStyles()

    return (<Grid>
                <FormControl className={classes.root}>
                    <InputLabel id={"version-selector-label-" + serviceName}>{serviceName}</InputLabel>
                    <Select
                        fullWidth
                        labelId="version-selector"
                        id={"version-selector-" + serviceName}
                        value={selected && versions.length > 0 ? selected : ''}
                        onChange={(e) => changeVersion(e.target.value, serviceName)}>
                        {
                            versions.map((version, index) => {
                                    return <MenuItem key={"version-menuitem-" + serviceName + "-" + index}
                                                     value={version}>{version}</MenuItem>
                                }
                            )
                        }
                    </Select>
                </FormControl>
            </Grid>
    )

}