import React from 'react'
import {Grid, Typography} from "@material-ui/core";

export default function Title({children}) {

    return (
        <Grid item xs={12}>
            <Typography variant="h5" component="h3">
                {children}
            </Typography>
        </Grid>
    )

}

