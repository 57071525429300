import React from 'react'
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";

export default function OrganizerRowHeader() {


    return (
        <TableHead>
            <TableRow>
                <TableCell align="center">Identity</TableCell>
                <TableCell align="center">Address</TableCell>
                <TableCell align="center">Ekratos Address</TableCell>
                <TableCell align="center">Backend Version</TableCell>
                <TableCell align="center">Frontend Version</TableCell>
                <TableCell align="center">Owner Identity</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Actions</TableCell>
            </TableRow>
        </TableHead>
    )
}